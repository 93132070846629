exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-js": () => import("./../../../src/pages/components.js" /* webpackChunkName: "component---src-pages-components-js" */),
  "component---src-pages-get-updates-js": () => import("./../../../src/pages/get-updates.js" /* webpackChunkName: "component---src-pages-get-updates-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-talking-with-your-doctor-js": () => import("./../../../src/pages/talking-with-your-doctor.js" /* webpackChunkName: "component---src-pages-talking-with-your-doctor-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-treatment-options-js": () => import("./../../../src/pages/treatment-options.js" /* webpackChunkName: "component---src-pages-treatment-options-js" */),
  "component---src-pages-understanding-dpn-js": () => import("./../../../src/pages/understanding-dpn.js" /* webpackChunkName: "component---src-pages-understanding-dpn-js" */)
}

